<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Medalhas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Medalhas da
                  <span>Plataforma</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <h4 class="aluno_font_color">
                    Lista de medalhas
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showModal('modalCriarMedalha')"
                  >
                    <small>+ Adicionar Medalha</small>
                  </button>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">
                          <small class="font-weight-bold">Nível</small>
                        </th>
                        <th>
                          <small class="font-weight-bold">Nome</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Imagem</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Acerto da prova</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Ações</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastMedalha.length">
                      <tr
                        v-for="r in fastMedalha"
                        :key="r.nivel"
                      >
                        <td class="text-center align-middle">
                          <small>{{ r.nivel_medalha }}</small>
                        </td>
                        <td class="align-middle">
                          <small>{{ r.nome_medalha }}</small>
                        </td>
                        <td class="text-center align-middle">
                          <small>
                            <img
                              :src="r.imagem_medalha"
                              width="70px"
                            >
                          </small>
                        </td>
                        <td class="text-center align-middle">
                          <small>{{ r.porcentagem_acerto_prova }}</small>
                        </td>
                        <td class="text-center align-middle">
                          <button
                            class="btn btn-sm btn-primary mr-2"
                            @click="exibeModalEditarMedalha(r)"
                          >
                            <small>Editar</small>
                          </button>
                          <button
                            class="btn btn-sm btn-danger"
                            @click="exibeModalExcluirMedalha(r)"
                          >
                            <small>Excluir</small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal
      name="modalCriarMedalha"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Criar medalha</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarMedalha')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nível</label>
              <input
                v-model="fastMedalhaNova.nivel_medalha"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nome da medalha</label>
              <input
                v-model="fastMedalhaNova.nome_medalha"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Porcentagem de acerto na avaliação</label>
              <input
                v-model="fastMedalhaNova.porcentagem_acerto_prova"
                class="form-control"
                type="number"
                min="1"
                max="100"
                placeholder
              >
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <div class="row">
                <div class="col-12 text-center">
                  <label class="d-block">Imagem da medalha</label>
                  <img
                    v-if="fastMedalhaNova.imagem_medalha"
                    class="d-block mb-2 m-auto"
                    :src="fastMedalhaNova.imagem_medalha"
                    style="max-width: 100%"
                  >
                </div>
              </div>

              <div class="row pl-3 pr-3 mt-2">
                <div class="col-12 p-0">
                  <input
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageMedalhaNova"
                  >
                  <label class="custom-file-label">Selecione uma imagem...</label>
                  <label class="message-field-label mt-2">Dimensões sugeridas: 70 x 70 pixels</label>
                   <div class="invalid-feedback">
                    Arquivo inválido
                  </div>
                  <modal
                    name="modalImageCropMedalhaNova"
                    height="auto"
                    :shift-y="0.1"
                    :focus-trap="true"
                  >
                    <div class="row">
                      <div class="col-12 pt-4 text-center">
                        <h4>Recorte a imagem</h4>
                      </div>
                      <div
                        v-if="fastMedalhaNova.imagem_medalha"
                        class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                      >
                        <vue-cropper
                          ref="cropper"
                          :src="fastMedalhaNova.imagem_medalha"
                          :auto-crop-area="1"
                          :aspect-ratio="1 / 1"
                        />
                        <div class="text-center mt-2">
                          <a
                            class="btn btn-primary"
                            href="#"
                            role="button"
                            @click.prevent="cropImageMedalhaNova()"
                          >Recortar</a>
                        </div>
                      </div>
                    </div>
                  </modal>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="criarMedalha()"
                >
                  <small>Criar medalha</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarMedalha"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar medalha</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarMedalha')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nível</label>
              <input
                v-model="fastMedalhaEditar.nivel_medalha"
                class="form-control"
                type="number"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nome da medalha</label>
              <input
                v-model="fastMedalhaEditar.nome_medalha"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Porcentagem de acerto na avaliação</label>
              <input
                v-model="fastMedalhaEditar.porcentagem_acerto_prova"
                class="form-control"
                type="number"
                min="1"
                max="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <div class="row">
                <div class="col-12 text-center">
                  <label class="d-block">Imagem da medalha</label>
                  <img
                    v-if="fastMedalhaEditar.imagem_medalha"
                    class="d-block mb-2 m-auto"
                    :src="fastMedalhaEditar.imagem_medalha"
                    style="max-width: 100%"
                  >
                </div>
              </div>

              <div class="row pl-3 pr-3 mt-2">
                <div class="col-12 p-0">
                  <input
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageMedalhaEditar"
                  >
                  <label class="message-field-label mt-2">Dimensões sugeridas: 70 x 70 pixels</label>
                  <label
                    class="custom-file-label"
                    for="banner_meus_cursos"
                  >Selecione uma imagem...</label>
                  <div class="invalid-feedback">
                    Arquivo inválido
                  </div>
                  <modal
                    name="modalImageCropMedalhaEditar"
                    height="auto"
                    :shift-y="0.1"
                    :focus-trap="true"
                  >
                    <div class="row">
                      <div class="col-12 pt-4 text-center">
                        <h4>Recorte a imagem</h4>
                      </div>
                      <div
                        v-if="fastMedalhaEditar.imagem_medalha"
                        class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                      >
                        <vue-cropper
                          ref="cropper"
                          :src="fastMedalhaEditar.imagem_medalha"
                          :auto-crop-area="1"
                          :aspect-ratio="1 / 1"
                        />
                        <div class="text-center mt-2">
                          <a
                            class="btn btn-primary"
                            href="#"
                            role="button"
                            @click.prevent="cropImageMedalhaEditar()"
                          >Recortar</a>
                        </div>
                      </div>
                    </div>
                  </modal>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarMedalha()"
                >
                  <small>Atualizar medalha</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirMedalha"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir medalha?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirMedalha')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirMedalha()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomeInternoAdminPersonalizarMedalhas",
  components: { VueCropper },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Medalha
      fastMedalha: [],
      fastMedalhaNova: {
        id_plataforma: 0,
        imagem_medalha: "",
        nivel_medalha: "",
        nome_medalha: "",
        porcentagem_acerto_prova: "",
      },
      fastMedalhaEditar: {
        id_plataforma: 0,
        imagem_medalha: "",
        nivel_medalha: "",
        nome_medalha: "",
        porcentagem_acerto_prova: "",
      },
      fastMedalhaExcluir: {
        id_plataforma: 0,
        imagem_medalha: "",
        nivel_medalha: "",
        nome_medalha: "",
        porcentagem_acerto_prova: "",
      },
      modalWidth: "80%",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastMedalha();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastMedalha() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_medalha/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastMedalha = obj;
        } else {
          this.fastMedalha = [];
        }
        console.log(json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    setImageMedalhaNova(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalImageCropMedalhaNova");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fastMedalhaNova.imagem_medalha = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImageMedalhaNova() {
      this.fastMedalhaNova.imagem_medalha = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();
      this.hideModal("modalImageCropMedalhaNova");
    },
    setImageMedalhaEditar(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalImageCropMedalhaEditar");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fastMedalhaEditar.imagem_medalha = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImageMedalhaEditar() {
      this.fastMedalhaEditar.imagem_medalha = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();
      this.hideModal("modalImageCropMedalhaEditar");
    },
    async criarMedalha() {
      let erros = [];
      if (!this.fastMedalhaNova.nivel_medalha)
        erros.push("O nível é obrigatório");
      if (!this.fastMedalhaNova.nome_medalha)
        erros.push("O nome da medalha é obrigatório");
      if (!this.fastMedalhaNova.imagem_medalha)
        erros.push("Uma imagem é obrigatória");
      if (!this.fastMedalhaNova.porcentagem_acerto_prova)
        erros.push("A porcentagem de acerto na prova é obrigatória");

      this.fastMedalha.forEach((e) => {
        if (e.nivel_medalha == this.fastMedalhaNova.nivel_medalha) {
          erros.push("O nível informado já existe");
        }
      });

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastMedalhaNova.id_plataforma = this.$route.params.id_plataforma;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_medalha/insere",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastMedalhaNova))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.getFastMedalha();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Nível criado com sucesso",
          });
          this.hideModal("modalCriarMedalha");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    async editarMedalha() {
      let erros = [];
      if (!this.fastMedalhaEditar.nivel_medalha)
        erros.push("O nível é obrigatório");
      if (!this.fastMedalhaEditar.nome_medalha)
        erros.push("O nome da medalha é obrigatório");
      if (!this.fastMedalhaEditar.imagem_medalha)
        erros.push("Uma imagem é obrigatória");
      if (!this.fastMedalhaEditar.porcentagem_acerto_prova)
        erros.push("A porcentagem de acerto na prova é obrigatória");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastMedalhaEditar.id_plataforma = this.$route.params.id_plataforma;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_medalha/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastMedalhaEditar))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.getFastMedalha();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Medalha atualizada com sucesso",
          });
          this.hideModal("modalEditarMedalha");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalEditarMedalha(r) {
      this.fastMedalhaEditar = r;
      this.showModal("modalEditarMedalha");
    },
    exibeModalExcluirMedalha(r) {
      this.fastMedalhaExcluir = r;
      this.showModal("modalExcluirMedalha");
    },
    async excluirMedalha() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_medalha/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastMedalhaExcluir))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastMedalha();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Medalha excluída com sucesso",
        });
        this.hideModal("modalExcluirMedalha");
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style>
  .message-field-label {
    display: flex;
    align-items: left;
    font-size: 12px;
    color:#495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
</style>
